import Vue from 'vue'
import Router from 'vue-router'
import HomePage from '@/pages/HomePage'
import NewsPage from '@/pages/NewsPage'
import NewsDetail from '@/pages/NewsDetail'
import ProjectsPage from '@/pages/ProjectsPage'
// import RepresentativePage from '@/pages/RepresentativePage'
import ProjectDetail from '@/pages/ProjectDetail'
import PeoplePage from '@/pages/PeoplePage'
import PeopleDetail from '@/pages/PeopleDetail'
import PublicationsPage from '@/pages/PublicationsPage'
import PublicationDetail from '@/pages/PublicationDetail'
import NotFound from '@/pages/NotFound'
import Login from '@/pages/Login'

Vue.use(Router)

export default new Router({

  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [{
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'ISL - Home'
    }
  }, {
    path: '/people',
    name: 'PeoplePage',
    component: PeoplePage,
    meta: {
      title: 'ISL - People'
    }
  }, {
    path: '/peopledetail',
    name: 'PeopleDetail',
    component: PeopleDetail,
    meta: {
      title: 'ISL - People Detail'
    },
    props: route => ({
      id: route.query.id
    })
  }, {
    path: '/projects',
    name: 'ProjectsPage',
    component: ProjectsPage,
    meta: {
      title: 'ISL - Projects'
    }
  }, {
    path: '/projectdetail',
    name: 'ProjectDetail',
    component: ProjectDetail,
    meta: {
      title: 'ISL - Project Detail'
    },
    props: route => ({
      id: route.query.id
    }),
  }, {
    path: '/news',
    name: 'NewsPage',
    component: NewsPage,
    meta: {
      title: 'ISL - News'
    }
  }, {
    path: '/newsdetail',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      title: 'ISL - News Detail'
    },
    props: route => ({
      id: route.query.id
    }),
  }, {
    path: '/publications',
    name: 'PublicationsPage',
    component: PublicationsPage,
    meta: {
      title: 'ISL - Publications'
    }
    },
    // { 隐藏代表作
    // path: '/representativepage',
    // name: 'RepresentativePage',
    // component: RepresentativePage,
    // meta: {
    //   title: 'ISL - Five Representative Papers'
    // }
    // },
    {
    path: '/publicationdetail',
    name: 'PublicationDetail',
    component: PublicationDetail,
    meta: {
      title: 'ISL - Publication Detail'
    },
    props: route => ({
      id: route.query.id
    }),
  }, {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'ISL - Login'
    }
  }, {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 Not Found'
    }
  }, ]
})