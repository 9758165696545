export default {
    navigation: {
        labName: "智能系统实验室",
        home: "主页",
        changeLanguage: "English",
        people: "成员",
        news: "新闻",
        projects: "项目",
        publications: "成果",
        index: "首页",
        representativepage:"代表作"
    },
    home: {
        introduction: "实验室介绍",
        recruit: "欢迎对智慧城市、联邦学习、多模态大模型、个性化智联系统等方向感兴趣的学生保送与报考硕士研究生和博士研究生。同时，实验室常年招收博士后以及专职科研人员，欢迎来信咨询。",
        introductionText: `
    智能系统强调信息与物理世界的融合，通过感知物理世界的信息变化、学习事物的发展与运行规律、制定优化且均衡的资源协调方案、响应多样且多变的个性化需求，不断提升系统整体的自主化服务、自组织运行能力，从孤岛式单体智能向协同式群体智能转变。
本实验室依托中山大学智能工程学院，联合国内外高校与研究机构，研究面向新型智慧城市的群体联邦计算基础理论与技术方法，并在自主交通、智慧医疗、新能源等领域开展应用示范。
    `,
        collaborator: "合作方",
        projects: "项目",
        publications: "成果",
        news: "新闻",
        positions: "职位",
        applyHere: "查看详情",
        researchers: "成员",
        more: "更多",
        allPublications: "所有成果",
        allResearchers: "所有成员",
        allNews: "所有新闻"
    },
    footer: {
        labName: "智能系统实验室",
        schoolName: "智能工程学院",
        universityName: "中山大学",
        address: "中国广东省深圳市光明区新湖街道公常路66号",
        postcode: "518107"
    },
    card: {
        detail: "查看全文",
        representwork: "代表作",
        personnal: "个人主页",
    },
    people: {
        staff: "师资力量",
        students: "学生",
        graduates: "毕业生",
        collaborators: "合作者",
        introduction: "介绍",
        course: "教授课程",
        work: "工作经历",
        education: "教育背景",
        research: "研究方向",
        project: "科研项目",
        paper: "代表性论著",
        professor: "教授",
        associateProfessor: "副教授",
        assistantProfessor: "助理教授",
        bachelorStudent: "本科生",
        masterStudent: "硕士生",
        phdStudent: "博士生",
        bachelor: "本科",
        master: "硕士",
        phd: "博士",
    },
    project: {
        fellows: "参与者",
        open: "开放资源",
        achievements: "成果",
        publications: "论著",
        other: "其他成果"
    },
}
